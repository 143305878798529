<template>
  <main>
    <h1>TEI 2025 Accepted Student Design Competition</h1>
    <p>We are happy to announce the accepted Student Design Competition for TEI25.</p>

    <ul>
      <li><span class="authors">Lingxiao Luo.</span>	<span class="title">Behave-Knit: Sustainable Integration of Pneumatic Actuators in Knitted Wearables.</span></li>
      <li><span class="authors">Thomas Elliott, Yanzhuo Yang.</span>	<span class="title">A Proposed Modification of the IxD Process: Sustainable Prototyping Practice for Physical Technology.</span></li>
      <li><span class="authors">Sarah Fletcher, Bennett Graff, Eunjin Hong, Vishaka Nirmal.</span>	<span class="title">WEFT Furniture.</span></li>
      <li><span class="authors">Divya Srinivasan, Chia-Jung Kuo.</span>	<span class="title">Beyond Substitutes: Crafting Unique Forms and Functions with Algae Bioplastics.</span></li>
      <li><span class="authors">Saskia Helinska.</span>	<span class="title">Biomaterial-Based Soft Robotics: Sustainable Alternatives to Vinyl and Silicone for Prototyping Haptic Interfaces.</span></li>

    </ul>
  </main>
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
