<template>
  <main>
    <h1>Bordeaux Public Transportation</h1>
    <p>We provide free 4-day tickets for public transport that you can use with the TBM app (<a
        href="https://play.google.com/store/apps/details?id=com.infotbm.tbm" target="_blank">Android</a> version, <a
        href="https://apps.apple.com/en/app/tbm-mobilités/id1492096341" target="_blank">iOS</a>
      version). Once installed, add your ticket by entering your personal code associated with your registration. More
      instructions to activate your code are available <a
          href="https://www.infotbm.com/en/im-attending-congress-bordeaux-metropole.html">here</a>.
    </p>
    <p>
      Please note that the ticket activates the moment you use it for the first time in any of the
      transport modes belonging to the TBM network (streetcar, bus, and boat). The ticket will be valid 4 days from
      activation irrespective of the conference dates. Any trips outside the ticket's validity can be purchased directly
      within the app for 1.50€.
    </p>
    <p>
      Please note that using the public transportation code requires a functioning internet connection when boarding
      public transportation, with location and Bluetooth enabled. If connections between different lines are made, then
      for each connection, the ticket needs to be scanned at the scanning stations inside buses and streetcars.
    </p>
    <h2>Mobile Internet in France</h2>
    <p>
      If you don’t have internet access from your mobile phone in France, you might consider using an eSIM during your
      stay which can be bought online before your trip and which can add data access to phones compatible with eSIMs.
      Advice can be found on the Web (e.g.
     <a href=" https://www.traveltomtom.net/destinations/europe/france/best-esim-cards-for-france#whentoorderanesimforfrance" target="_blank"> here</a>). If
      you do not have mobile internet access and your phone is not compatible with eSIMs, please let us know by Monday.
    </p>
  </main>
</template>

<script>

</script>

<style scoped>

</style>