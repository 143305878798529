<script setup>

</script>

<template>
  <main>
    <h1>Keynote speakers</h1>

    <div class="two-columns">
      <div>
        <img src="@/assets/bt_img.webp">

        <h2>Barbara Tversky — Opening Keynote</h2>

      </div>
      <div>
        <br>
        <b>KEYNOTE ABSTRACT</b><br>
        All living creatures must move and act in space and with the things in it to survive. Those interactions are
        constantly tuned and refined, and coopted for other interactions. In people, and perhaps other living things,
        they form a or the foundation for abstract thought, audacious claims that will be supported. These interactions
        present challenges and opportunities for digital interactions and for AI.

        <br>
        <br>
        <b>ABOUT BARBARA TVERSKY</b><br>
        Barbara Tversky is a cognitive psychologist, Professor Emerita at Stanford University and Columbia Teachers
        College. She also held positions at Hebrew University of Jerusalem, the University of Michigan, Malardalen
        University and the Ecole des Hautes Etude en Sciences Sociales. Her research has spanned memory processes and
        distortions, categorization, spatial thinking and language, event perception and cognition, story-telling,
        diagrammatic reasoning, design, HCI, joint action, gesture, and creativity. She has enjoyed collaborations with
        computer scientists, linguists, neuroscientists, domain scientists, designers, and artists. She has served on
        many editorial and governing boards, nationally and internationally, and was president of the Association of
        Psychological Science. She is a fellow of the Cognitive Science Society, the Association of Psychological
        Science, the Russell Sage Foundation, and the Psychonomic Society. She is a recipient of the Kampe de Feriat
        Award and was elected to the Society for Experimental Psychology and the American Association of Arts and
        Science.
      </div>
      <br>
      <div>
        <img src="@/assets/auger.webp">

        <h2>James Auger — Closing Keynote</h2>

      </div>
      <div>
        <br>
        <b>KEYNOTE ABSTRACT </b><br>
        Design practice always happens under a particular set of forces or conditions, commonly known as constraints.
        These constraints may be straightforward and indisputable, such as a physical or material quality—the force of
        gravity or the tensile strength of a structural beam. They can be the subject of discussion and compromise, such
        as a financial cost or a timeline. They can relate to aesthetic or cultural considerations, such as a fashion
        trend or social movement. Constraints of this basic type influence the design process by providing tangible
        limits that can be adhered to or challenged.

        But constraints also exist in more covert, abstract or oblique forms, such as national infrastructure systems
        like energy grids. These become so normalised that they force designers to simply design for or within the
        dominant paradigm. Myths of progress act to reduce the technological future to recycled utopian imaginaries that
        maintain the status quo and divert attention from its fundamental flaws, and constraints imposed by design's
        economic relationship with the market encourage, among other things, questionable approaches to resources,
        labour, distribution and repair.

        This presentation will firstly explore some of the more dominant oblique constraints and the ways in which they
        negatively influence the role and purpose of design. The second part will describe the approach of Reconstrained
        Design, which takes the identified constraints and develops ways to reverse, work around or simply ignore them.
        This expands the potential of the design and the designer's ability to radically rethink modes of practice


        <br>
        <br>
        <b>ABOUT JAMES AUGER</b><br>
        James Auger is director of the design department at the École normale supérieure Paris-Saclay (ENS) and
        co-director of the Centre de Recherche en Design (ENS / ENSCI Les Ateliers). His work explores ways through
        which practice-based design research can lead to more considered and democratic technological futures.
        After graduating from Design Products (MA) at the Royal College of Art in London James moved to Dublin to
        conduct research at Media Lab Europe (MLE) exploring the theme of human communication as mediated by technology.
        After MLE he worked in Tokyo as guest designer at the Issey Miyake Design Studio developing new concepts for
        mobile telephones. Between 2005 and 2015 James was part of the critically acclaimed Design Interactions
        department at the RCA, teaching on the MA programme and continuing his development of critical and speculative
        approaches to design and technology, completing his PhD on the subject in 2012. After the RCA James formed the
        Reconstrained Design Group at Madeira Interactive Technologies Institute (M-ITI) in Portugal, exploring the
        potential of the island as an experimental living laboratory through a combination of fictional, factual and
        functional multi-scale energy-related proposals and projects. This work was awarded the Cultural Innovation
        International Prize by the Centre of Contemporary Culture of Barcelona (CCCB) in 2017.
        Running parallel to his academic work James is a partner in the speculative design practice Auger-Loizeau, a
        collaboration founded in 2000. Auger-Loizeau projects have been published and exhibited internationally,
        including MoMA, New York; 21_21, Tokyo; The Science Museum, London; The National Museum of China, Beijing and
        Ars Electronica, Linz. Their work is in the permanent collection at MoMA.
        <br>
        website: <a href="http://reconstrained.design" target="_blank">http://reconstrained.design</a>
        <br>
        blog: <a href="http://crapfutures.tumblr.com" target="http://crapfutures.tumblr.com"> http//crapfutures.tumblr.com</a>

      </div>
    </div>
  </main>
</template>

<style scoped>
h2 {
  text-align: center;
}

img {
  display: block;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 100%;
  margin: auto;
}

@media (min-width: 800px) {
  .two-columns {
    display: block;
    grid-template-columns: 200px auto;
    grid-gap: 40px;
  }
}
</style>
