<template>
  <main>
    <h1>TEI 2025 Accepted Art&Performances</h1>
    <h2>Handle with care</h2>
    An “Exhibition-in-suitcases” for the 19th ACM International Conference on Tangible, Embedded and Embodied Interaction, Bordeaux, 2025
    <br>
    <h2>Theme and protocol</h2>

    <p>Between 1936 and 1941, artist Marcel Duchamp dedicated himself to creating miniature reproductions of his most significant works, allowing him to place them within a single box and transport them in a suitcase. This effort resulted in his renowned Boîte-en-Valise (Box-in-a-Suitcase). Regarded as a portable museum, this work facilitates the spatial presentation of a novel form of exhibition through the meticulous arrangement of the box and its contents.</p>

    <p>The qualities of being portable, interactive, and deployable are common features observed in the types of artworks often encountered at TEI (Tangible, Embedded, and Embodied Interaction) conferences. These conferences bring together hundreds of researchers, designers, artists, and makers for three days of meetings, performances, and exhibitions, generating significant economic and ecological impact. Therefore, this year we propose a reconsideration of exhibition practices to embrace sustainable tangibility, portability, and deployability, in accordance with TEI '25 theme “sustainable TEI”.</p>

      <p> Inspired by Marcel Duchamp's approach, we propose an "Exhibition-in-Suitcases" for TEI 2025, wherein all participants adhere to a shared protocol centred around a common object: a suitcase. In line with the conference's theme of sustainability, projects should be designed or adapted to be both presentable and deployable from a suitcase, reflecting a commitment to resourcefulness and adaptability. On-site, in adherence to this protocol, a straightforward yet elegant scenography, will be devised to optimize the potential of this shared device marked by the presence of visible suitcases, serving as focal points of the exhibition space.</p>

      <p>The exhibition will be open on Thursday, 6th of May, from 7 PM to 12 AM at CAPC, in Bordeaux.</p>


    <ul>
      <li><span class="authors">Stefan Escaida Navarro (Universidad de O’Higgins Rancagua, Chile); Carlos Rose (Universidad de O’Higgins Rancagua, Chile); Carolina Silva Plata (Universidad de O’Higgins Rancagua, Chile); Mario Sanz-López (CNRS, Lille, France); Yosra Mojtahedi (Independent Artist, Loos, France)</span>
        <span class="title">Sexus Fleurus: A Tactile Soft Robotic Installation</span></li>
      <li><span class="authors">Ryuichi Ono (Graduate School of Systems Design, Tokyo Metropolitan University, Hino, Tokyo, Japan); Tetsuaki Baba (Graduate School of Systems Design, Tokyo Metropolitan University, Hino, Tokyo, Japan)</span>
        <span class="title">Tangible VFX : Dialogue with the Fourth Dimension</span></li>
      <li><span class="authors">Mirela Alistar (ATLAS Institute & Computer Science, University of Colorado Boulder, Boulder, Colorado, United States); Shira David (ATLAS Institute , University of Colorado Boulder , Boulder, Colorado, United States); Eldy S. Lazaro Vasquez (ATLAS Institute, University of Colorado Boulder, Boulder, Colorado, United States)</span>
        <span class="title">Engaging with the making and the end-of-life of artificial interactive nails</span></li>
      <li><span class="authors">Mikhail Mansion (Graduate School of Interdisciplinary Information Studies / xlab, The University of Tokyo, Hongo-ku, Tokyo-to, Japan); Kuan-Ju Wu (Graduate School of Interdisciplinary Information Studies, University of Tokyo, Tokyo, Japan);  Yasuaki (Kakehi The University of Tokyo, Tokyo, Japan)</span>
        <span class="title">Heat Waves: Thermoacoustics in Ecological Sound Art</span></li>
      <li><span class="authors">Antoni Rayzhekov (University of Salzburg, Salzburg, Austria); Martin Murer (University of Salzburg, Salzburg, Austria)</span>
        <span class="title">Sweepcase: (Un)Packing Arbitrary Objects for Tangible Interaction</span></li>
      <li><span class="authors">Kristof Timmerman (Royal Academy of Fine Arts Antwerp, AP University of Applied Sciences and Arts, Antwerp, Belgium); Rob Mertens (Immersive Lab, AP University of Applied Sciences and Arts Antwerp, Antwerp, Belgium);  Arafa Yoncalik (Immersive Lab, AP University of Applied Sciences and Arts, Antwerp, Belgium); Lowie Spriet (Immersive Lab, AP University of Applied Sciences and Arts Belgium, Antwerp, Belgium)</span>
        <span class="title">Cities Unseen: Experiencing the Imagined</span></li>
      <li><span class="authors">Jamie Gledhill (Norwich University of the Arts, Norwich, Norfolk, United Kingdom)</span>
        <span class="title">Suitcase Stories</span></li>
      <li><span class="authors">Iurii Kuzmin (Individualized Interdisciplinary Program (IIP), The Hong Kong University of Science and Technology, Hong Kong, Hong Kong); Omar Al Kanawati (China Academy of Art, Hangzhou, Zhejiang province, China); Raul Masu (Hong Kong University of Science and Technology (Guangzhou), Computational Media and Art Guangzhou, China)</span>
        <span class="title">Reverse Movement</span></li>
      <li><span class="authors">Heidi Biggs (School of Literature, Media, and Communication, Georgia Institute of Technology, Atlanta, Georgia, United States)</span>
        <span class="title">Soft Sound Geographies: Textile Maps of Land Body Data Entanglements</span></li>
      <li><span class="authors">Foad Hamidi (Information Systems Department, University of Maryland, Baltimore County, Baltimore, Maryland, United States); Linda Dusman (University of Maryland, Baltimore County, Baltimore City, Maryland, United States); Lee Boot (Imaging Research Center, University of Maryland, Baltimore County, Baltimore, Maryland, United States)</span>
        <span class="title">Infinite Transformations in a Suitcase: Encountering Human-DNA Interaction through Poetry-infused Wine</span>
      </li>
      <li><span class="authors">Fiona Bell (Department of Computer Science, University of New Mexico, Albuquerque, New Mexico, United States)</span>
        <span class="title">Pu-239/240-LANL-45-75: Engaging with Historical Plutonium Radiation Data</span></li>
      <li><span class="authors"> Adrien Chaffangeon Caillet (Laboratoire d'Informatique de Grenoble, Grenoble, France Berlin University of Applied Sciences, Berlin, Germany); Jasper Flügge (Berlin University of Applied Sciences, Berlin, Germany); Eric Chaffangeon (3D Model Conception & Usinage, Neulise, France); Katrin Wolf Beuth (University of Applied Sciences, Berlin, Germany)</span>
        <span class="title">The not-so-masochist teapot</span></li>
      <li><span class="authors">Botao Amber Hu (Reality Design Lab, New York City, New York, United States); Ke Huang (China Academy of Art, Hangzhou, China); Danlin Huang (China Academy of Art, HangZhou, China); Cun Lin (China Academy of Art, Hangzhou, China)</span>
        <span class="title">Body Oracle: Speculative Hieroglyphs for Collective Bodily Awareness</span></li>
      <li><span class="authors">Brandon Ables (University of Maryland, Baltimore County, Baltimore, Maryland, United States)</span>
        <span class="title">Carry-on for Consciousness: An Embodied Travel Writer Trainer</span></li>
      <li><span class="authors">BOTAO AMBER HU (Reality Design Lab, United States of America); REM RUNGU LIN (Hong Kong University of Science and Technology, Guangzhou, China); KOO YONGEN KE (Fun Theory/BeFun Lab, China); WEI WU (BNU-HKBU United International College, China); KANG ZHANG (Hong Kong University of Science and Technology, Guangzhou, China)</span>
        <span class="title">Cell Space</span></li>
      <li><span class="authors">Catarina Reis (CIEBA, Faculty of Fine Arts University of Lisbon, Lisbon, Lisboa, Portugal; ITI - Interactive Technologies Institute, LARSYS - University of Lisbon, Lisbon); Mónica Mendes (ITI/LARSyS, Faculdade de Belas-Artes, Universidade de Lisboa, CIEBA)</span>
        <span class="title">Still Life Ecologies: Co-creating the representation of plants</span></li>
      <li><span class="authors">Pedro Vargas Ruiz (ENSAD LAB, Université PSL, Paris, France)</span>
        <span class="title">Electrostimulation system for a non-figurative </span></li>
      <li><span class="authors">Jiabao Li (The University of Texas at Austin, The University of Tokyo); Ben Evanson (The University of Texas at Austin)</span>
        <span class="title">Carbon Farm</span></li>
    </ul>

  </main>
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
