<template>
  <main>
    <h1>TEI 2025 Accepted Graduate Student Consortium</h1>
    <p>We are happy to announce the accepted Graduate Student Consortium for TEI25.</p>

    <ul>
      <li><span class="authors"> Anna Bertmark.</span>	<span class="title">Artefacts as Pedagogy for Futuring.</span></li>
      <li><span class="authors"> Drew Ehrlich.</span>	<span class="title">Designing Tangible Devices and Interfaces for Closing Institutional Resource Gaps in Neuroscience.</span></li>
      <li><span class="authors"> Adina Friedman.</span>	<span class="title">The Relationship Between Interaction Paradigm, Engagement, and Learning.</span></li>
      <li><span class="authors"> Siyi Liu.</span>	<span class="title">Exploring Human Augmentation Design Knowledge Through Unfortunate Superpower Experiences.</span></li>
      <li><span class="authors"> Ziyue Piao.</span>	<span class="title">Exploring Haptics to Augment Kinesthetic Learning in Singing.</span></li>
      <li><span class="authors"> Krithik Ranjan.</span>	<span class="title">Tangible Interfaces for Computational Tinkering.</span></li>
      <li><span class="authors"> Philipp Thalhammer.</span>	<span class="title">Generative AI Meets Accessibility: Deformable Interfaces and Multimodal Solutions.</span></li>
      <li><span class="authors"> Kuan-Ju Wu.</span>	<span class="title">Designing with Water: From Material to Metaphor to More-than-Human Mindset.</span></li>
    </ul>
  </main>z
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
