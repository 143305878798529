<template>
  <main>
    <h1>Arts and Performance</h1>

    <h1 style="color: var(--text)">Handle with care</h1>
    <b>An “Exhibition-in-suitcases” for the 19th ACM International Conference on Tangible, Embedded and Embodied Interaction, Bordeaux, 2025</b>

    <h2>Important Dates</h2>
    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Full submission deadline
        </td>
        <td>
          <s>October 24, 2024, AoE</s> <br>
          October 31, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of acceptance
        </td>
        <td>
          November 23, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          December 4, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>

    <h2>Theme and protocol</h2>
    <p>
      Between 1936 and 1941, artist Marcel Duchamp dedicated himself to creating miniature reproductions of his most significant works, allowing him to place them within a single box and transport them in a suitcase. This effort resulted in his renowned Boîte-en-Valise (Box-in-a-Suitcase). Regarded as a portable museum, this work facilitates the spatial presentation of a novel form of exhibition through the meticulous arrangement of the box and its contents.
    </p>
    <p>
    The qualities of being portable, interactive, and deployable are common features observed in the types of artworks often encountered at TEI (Tangible, Embedded, and Embodied Interaction) conferences. These conferences bring together hundreds of researchers, designers, artists, and makers for three days of meetings, performances, and exhibitions, generating significant economic and ecological impact. Therefore, this year we propose a reconsideration of exhibition practices to embrace sustainable tangibility, portability, and deployability, in accordance with TEI '25 theme “sustainable TEI”.
    </p>

    <p>
    Inspired by Marcel Duchamp's approach, we propose an "Exhibition-in-Suitcases" for TEI 2025, wherein all participants adhere to a shared protocol centred around a common object: a suitcase. In line with the conference's theme of sustainability, projects should be designed or adapted to be both presentable and deployable from a suitcase, reflecting a commitment to resourcefulness and adaptability. On-site, in adherence to this protocol, a straightforward yet elegant scenography, will be devised to optimize the potential of this shared device marked by the presence of visible suitcases, serving as focal points of the exhibition space.
    </p>

    <p><b>Participants who wish to deviate from this format are welcome to do so, provided they offer a brief explanation of why adherence is not feasible.</b></p>

    <h2>Topics</h2>
    <p>
    We seek submissions that engage with the TEI conference theme ‘sustainable’ as well as topics including, but not limited to:
    </p>
    <ul>
      <li>
        The crossing of boundaries between disciplines in art, music, performance, crafts and science.
      </li>
      <li>
        Material engagements as related to human and non-human entanglements, post-humanism, sustainability, augmented, hybrid bodies or more-than-human worlds.
      </li>
      <li>
        Reflections on the social, political, ethical, and aesthetic dimensions of hybrid and multi-faceted systems.
      </li>
      <li>
        Approaches to layered engagements with heritage and tradition, though cohabitation, making and (digital) craftspersonship.
      </li>
    </ul>


    <h2>Submission guidelines</h2>
    <p>
      All proposals must be submitted electronically via the <a href="https://new.precisionconference.com/">Precision Conference (PCS) website</a>.
    </p>
    <p>
    Accepted works will be exhibited in the Arts & Performance Track exhibition at a venue in Bordeaux as part of the TEI’25 conference. The authors are expected to finance their travel and material budgets. One author of each accepted submission must pay for registration for the conference before the early registration deadline.
    </p>
    <p>
    The Arts & Performance Track welcomes submissions of artworks from a wide range of practitioners and researchers in areas such as arts, music, design, new media, biology, material science, and technology. We welcome submissions from artists at all career stages, including students and independent practitioners.
    </p>
    <p>
    Works submitted to the Art and Performance Track can either be extended to be presented as optional performances or demos, or can be specifically handed in as exhibit or performance only. On the submission form you will be asked to indicate whether your submission should be considered as an exhibit and/or performance.
    Performances should be intentionally crafted or adapted to adhere to the suitcase protocol. This can involve deploying performance-related objects from the suitcase, incorporating the suitcase as a meaningful relational object or accessory within the performance, or seamlessly integrating it into the scenography.
    </p>

    <p>The submission should include:</p>
    <ul>
      <li>
        Artwork/performance extended paper discussing the artwork theme and description (see Format below).
      </li>
      <li>
        A detailed explanation of how the artwork or performance will be integrated with the suitcase is required. If compliance with the suitcase protocol is not possible, please provide a clear rationale for this deviation.
      </li>
      <li>
        Detailed information including the spatial and technical specifications required to present your work. If you are submitting a performance proposal, include the duration. Note that performances should not exceed 12 minutes. While the TEI organization will make efforts to accommodate these needs within the limits of its available space and resources, it is important to note that priority will be given to projects that conform to the "Exhibition-in-suitcases" protocol.
      </li>
      <li>
        Documentation of work such as images, videos, etc. Please add the content or a link to the content in the artwork paper pdf. The links should be unrestricted to ensure easy access for the reviewing process.
      </li>
      <li>
         Separate pdf document with a technical requirement description such as space, light, connectivity and furniture.
      </li>
      <li>
        <i>Optional</i>: An ethics statement. Any discussion point in the ethical statements of NIME publications is a good start: <a href="https://www.nime.org/ethics/">https://www.nime.org/ethics/</a>
      </li>
      <li>
        An artist biography for the participating artist(s) (300 words or fewer per bio).
      </li>
    </ul>

    <h2>Format Requirements</h2>
    <p>
      TEI 2024 uses the <a href="https://www.acm.org/publications/taps/word-template-workflow">new ACM workflow</a> for submission templates and published papers. This requires the use of a simplified one-column template for submission, while the final two-column paper has to <a href="https://www.acm.org/publications/authors/submissions">be rendered for publication</a> after acceptance. Submissions should be in the form of a short (2 – 4 pages plus references) paper in PDF format that will appear in the TEI Proceedings and will be indexed in the ACM Digital Library. Authors can choose to use either Word (<a href="https://authors.acm.org/proceedings/production-information/preparing-your-article-with-microsoft-word">template can be downloaded from the ACM website</a>) or Latex (<a href="https://authors.acm.org/proceedings/production-information/preparing-your-article-with-latex">template on the ACM website</a> or on <a href="https://www.overleaf.com/gallery/tagged/acm-official#.WOuOk2e1taQ">Overleaf</a>). For further details please follow the provided instructions or contact our Publication Chair.
    </p>

    <h2>Policy on Use of Large Language Models</h2>
    <p>
    Text generated from a large-scale language model (LLM), such as ChatGPT, must be clearly marked where such tools are used for <b>purposes beyond editing the author’s own text</b>. Please carefully review the April 2023 <a href="https://www.acm.org/publications/policies/new-acm-policy-on-authorship">ACM Policy on Authorship</a> before you use these tools. The <a href="https://medium.com/sigchi/acm-publications-policy-guidance-for-sigchi-venues-87332173aad1">SIGCHI blog post</a> describes approaches to acknowledging the use of such tools and we refer to it for guidance. While we do not anticipate using tools on a large scale to detect LLM-generated text, we will investigate submissions brought to our attention and <b>desk-reject</b> papers where LLM use is not clearly marked.
    </p>
    <p>
      If your submission contains images, videos, or sounds generated using tools such as DALL-E, Midjourney, Stable Diffusion, or similar, please mention this in the artwork or performance description.
    </p>

    <h2>File Size</h2>
    <p>
    Please note that the maximum size of your submission should not exceed 300 Mb. It will be possible to submit large videos via a link to online content (e.g., YouTube or Vimeo). Arts Track reviews are single-blind, the submissions should therefore include the author(s) name and affiliation.
    </p>
    <p>
    Submissions are submitted through the PCS submission system.
    </p>

    <h2>Selection Process</h2>
    <p>
    Submissions will be selected based on their artistic merits and their engagement with issues relevant to the field of tangible, embedded, and embodied interactive art and alignment with the conference’s theme “sustainable TEI”. In addition, the logistical constraints of the venue may determine the eligibility of submitted projects. Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity.
    </p>

    <p>Submissions will be reviewed on the following aspects:</p>
    <ul>
      <li><b>Relevance</b> – The work must have a tangible aspect, in the form of an installation, performance, object, or physical event, aligned with the conference theme ‘sustainable’.</li>
      <li><b>Artistic</b> – It must operate significantly and compellingly through its formal, conceptual, and experiential properties.</li>
      <li><b>Embodiment</b> – The work must engage the human body beyond the traditional screen-pointer interface.</li>
      <li><b>Computational aspect</b> – It must involve computational technology (digital, biological, or analog) in some aspect of its form or function.</li>
      <li><b>Positioning</b> – The work must be positioned within related areas of theory and/or practice.</li>
      <li><b>Feasibility</b> – The submission should include all details to realise the work, including technical details, budget, and feasibility explanation.</li>
      <li><b>Protocol</b> – The way that the proposal plays with the “Exhibition-in-suitcases” protocol.</li>
    </ul>

    <h2>Attendance</h2>
    <p>
    Artists (or their delegates) will need to be available for installation and bump-out, and for facilitating interaction with their artwork at scheduled times. The authors are expected to finance their travel and material budgets.
    </p>
    <p>
    <b>Please note:</b> to participate in the conference, authors must submit a paper that will be included in the conference proceedings. Additionally, at least one author from each accepted submission must pay for registration for the conference by the early registration deadline.
    </p>

    <h2>Arts and Performance Chairs</h2>
    Samuel Bianchini, <i>Ensadlab, Ensad</i>, France<br>
    Filipe Pais, <i>Noroff University College, NO / Ensadlab, Ensad</i>, France<br>
    <br>
    For further questions please contact the Work-In-Progress chairs at <a href="mailto:arts_chairs@tei.acm.org">arts_chairs@tei.acm.org</a>

  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}
</style>
