<template>
  <main>
    <h1>Registration</h1>

    <p>
      We tried to make TEI 2025 registration as accessible as possible while still covering the expenses of the
      conference. Registration is now open through the CVENT online registration system: <a
        href="https://cvent.me/BqDEqa" target="_blank">https://cvent.me/BqDEqa</a>. Please note that for every accepted
      <strong>Paper</strong>, <strong>Pictorial</strong>, <strong>Arts</strong>, or <strong> Work-in-Progress</strong> submission, at
      least one author has to register personally <b>before the early bird deadline</b>.
    </p>
    <div style=" display: flex;
  justify-content: center;
  align-items: center;">
      <button class="buttonRegistration" role="button">
        <a href="https://cvent.me/BqDEqa" target="_blank" style="color: #000000;">Register here<br>
          <span class="small-text">(full conference)</span></a></button>
    </div>
    <h2>Conference Registration & Fees
    </h2>
    <p>Registration to TEI 2025 will give you access to the live and interactive program. Full, in-person conference
      registration includes access to all sessions, coffee breaks, Tuesday Welcome reception, and conference dinner on
      Thursday where demos and art+performance session will take place. Online participation includes access to the live
      stream of the TEI’25 single track and provides access to a live-streamed tour of the demo and art & performance
      track session.</p>

    <p>TEI offers different pricing by the country of your current home address. C countries pay the full price, H countries pay 40% of the cost (60% discount) and I countries pay 25% of the cost (75% discount). See the list of countries in each category at the end of this page.</p>

    <!-- HTML !-->


    <table class="table_component" role="region" tabindex="0">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th style=" text-align: center;">IN-PERSON</th>
        <th style=" text-align: center;" colspan="2">ONLINE</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Content</td>
        <td>
          <ul>
            <li>All sessions</li>
            <li>Coffee breaks</li>
            <li>Welcome reception</li>
            <li>Conference dinner</li>
          </ul>
        </td>
        <td colspan="2">
          <ul>
            <li>Live stream of TEI'25</li>
            <li>Live tour of the demos and art track</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td rowspan="2">Price</td>
        <td rowspan="2">See the table below</td>
        <td>Authors</td>
        <td>€350</td>
      </tr>
      <tr>
        <td>Others</td>
        <td>€30</td>
      </tr>
      </tbody>
    </table>

    <div class="table_component" role="region" tabindex="0">
      <table id="t">
        <caption></caption>
        <thead>
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th style=" text-align: center;">IN-PERSON</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="5">Early <s>(January 24th)</s> (February 4st)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€600</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€350</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€400</td>
        </tr>
        <tr class="thick-border">
          <td>Retiree</td>
          <td>€350</td>
        </tr>
        <tr>
          <td rowspan="5">Standard (February 12th)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€500</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€550</td>
        </tr>
        <tr class="thick-border">
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        <tr>
          <td rowspan="5">Late (March 5th)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€550</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€600</td>
        </tr>
        <tr>
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p id="studio_registration">Potential attendee can also benefit from the <a href="https://sigchi.org/awards/gary-marsden-travel-awards/"
                                                       target="_blank">Gary Marsden Travel Awards</a> and <a
        href="https://sigchi.org/resources/sigchi-development-fund/" target="_blank">Development Fund</a>. For more
      information, check the link: <a href="https://sigchi.org/community/membership/" target="_blank">
        https://sigchi.org/community/membership/ </a>
    </p>

    <h2 >Studio Registration & Fees</h2>
    You can choose to register for just one studio. In this case, a studio will cost 45€ if you are a student, and 55€ if you are not. This registration only gives access to the TEI on March 4 (TEI studio day).
    <div style=" display: flex;
  justify-content: center;
  align-items: center;">
      <button class="buttonRegistration" role="button">
        <a href="https://cvent.me/Mmk54K" target="_blank" style="color: #000000;">Register here<br>
          <span class="small-text">(studio only)</span></a></button>
    </div>
    <p>
      <h2>Categories (country list)</h2>

      <b>Category C</b>

      <p>All countries not listed in category H or I.</p>

      <b>Category H</b>

      <ul>
      <li>Albania</li>
      <li>Argentina</li>
      <li>Armenia</li>
      <li>Azerbaijan</li>
      <li>Belarus</li>
      <li>Belize</li>
      <li>Bosnia</li>
      <li>Botswana</li>
      <li>Brazil</li>
      <li>Bulgaria</li>
      <li>China</li>
      <li>Colombia</li>
      <li>Costa Rica</li>
      <li>Cuba</li>
      <li>Dominica</li>
      <li>Dominican Republic</li>
      <li>Ecuador</li>
      <li>El Salvador</li>
      <li>Equatorial Guinea</li>
      <li>Fiji</li>
      <li>Gabon</li>
      <li>Georgia</li>
      <li>Grenada</li>
      <li>Guatemala</li>
      <li>Indonesia</li>
      <li>Iraq</li>
      <li>Jamaica</li>
      <li>Kazakhstan</li>
      <li>Kosovo</li>
      <li>Libya</li>
      <li>North Macedonia</li>
      <li>Malaysia</li>
      <li>Maldives</li>
      <li>Marshall Islands</li>
      <li>Mauritius</li>
      <li>Mexico</li>
      <li>Montenegro</li>
      <li>Namibia</li>
      <li>Palau</li>
      <li>Palestine</li>
      <li>Paraguay</li>
      <li>Peru</li>
      <li>Republic Moldova</li>
      <li>Russian Federation</li>
      <li>Saint Lucia</li>
      <li>Serbia</li>
      <li>South Africa</li>
      <li>St. Vincent</li>
      <li>Suriname</li>
      <li>Thailand</li>
      <li>Tonga</li>
      <li>Turkey</li>
      <li>Turkmenistan</li>
      <li>Tuvalu</li>
      <li>Venezuela</li>
      </ul>

      <b>Category I</b>

      <ul>
      <li>Afghanistan</li>
      <li>Algeria</li>
      <li>Angola</li>
      <li>Bangladesh</li>
      <li>Benin</li>
      <li>Bhutan</li>
      <li>Bolivia</li>
      <li>Burkina Faso</li>
      <li>Burundi</li>
      <li>C African Rp</li>
      <li>Cambodia</li>
      <li>Cameroon</li>
      <li>Cape Verde</li>
      <li>Chad</li>
      <li>Comoros</li>
      <li>Congo</li>
      <li>Congo, Democratic Republic</li>
      <li>Djibouti</li>
      <li>Egypt</li>
      <li>Eritrea</li>
      <li>Eswatini</li>
      <li>Ethiopia</li>
      <li>Federal State of Micronesia</li>
      <li>Gambia</li>
      <li>Ghana</li>
      <li>Guinea</li>
      <li>Guinea-Bissau</li>
      <li>Haiti</li>
      <li>Honduras</li>
      <li>India</li>
      <li>Iran</li>
      <li>Ivory Coast</li>
      <li>Jordan</li>
      <li>Kenya</li>
      <li>Kiribati</li>
      <li>Kyrgyzstan</li>
      <li>Lebanon</li>
      <li>Lesotho</li>
      <li>Liberia</li>
      <li>Madagascar</li>
      <li>Malawi</li>
      <li>Mali</li>
      <li>Mauritania</li>
      <li>Mongolia</li>
      <li>Morocco</li>
      <li>Mozambique</li>
      <li>Myanmar</li>
      <li>Nepal</li>
      <li>Nicaragua</li>
      <li>Niger</li>
      <li>Nigeria</li>
      <li>North Korea</li>
      <li>Pakistan</li>
      <li>Papua New Guinea</li>
      <li>People’s Dem. Republic of Lao</li>
      <li>Philippines</li>
      <li>Rwanda</li>
      <li>Samoa</li>
      <li>Sao Tome and Principe</li>
      <li>Senegal</li>
      <li>Sierra Leone</li>
      <li>Sri Lanka</li>
      <li>Solomon Isl</li>
      <li>Somalia</li>
      <li>South Sudan</li>
      <li>Sudan</li>
      <li>Syria</li>
      <li>Tadzhikistan</li>
      <li>Tanzania</li>
      <li>Timor-Leste</li>
      <li>Togo</li>
      <li>Tunisia</li>
      <li>Uganda</li>
      <li>Ukraine</li>
      <li>Uzbekistan</li>
      <li>Vanuatu</li>
      <li>Viet Nam</li>
      <li>Yemen</li>
      <li>Zambia</li>
      <li>Zimbabwe</li>
      </ul>
    </p>

    <p>
      * SIGCHI membership costs $30 for professionals and $10 for students. For more info, visit <a
        href="https://sigchi.org" target="_blank">sigchi.org</a>.
    </p>
  </main>
</template>

<script setup lang="js">
import {onMounted, nextTick} from 'vue'
import router from '@/router'

onMounted(() => {
  const section = router.currentRoute.value.hash.replace("#", "")
  if (section) {
    nextTick(() => window?.document?.getElementById(section)?.scrollIntoView())
  }
})
</script>

<style scoped>

.table_component {
  overflow: auto;
  width: 100%;
}

.table_component table {
  border: 1px solid var(--background-alt);
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
}

.table_component caption {
  caption-side: top;
  text-align: left;
}

.table_component th {
  border: 1px solid var(--background-alt);
  background-color: var(--foreground);
  color: var(--text);
  padding: 5px;
}

.table_component td {
  border: 1px solid var(--background-alt);
  background-color: var(--background);
  color: var(--text);
  padding: 5px;
}

.table_component tr td:first-child {
  width: 100px;
}

.thick-border {
  border-bottom: 5px solid var(--background-alt);
}

/* CSS */
.buttonRegistration {
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #000000;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  /*font-family: Inter, sans-serif;*/
  font-size: 18px;
  justify-content: center;
  line-height: 28px;
  max-width: 100%;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  min-width: 25%;
  max-width: 50%;
  font-family: 'American Typewriter', serif;
  font-weight: normal;

}

.buttonRegistration:active,
.buttonRegistration:hover {
  outline: 0;
}

.buttonRegistration:hover {
  background-color: #FFFFFF;
  border: 1px solid #0077b6;
  font-weight: bold;
  min-width: 50%;
}

@media (min-width: 768px) {
  .buttonRegistration {
    font-size: 20px;
    min-width: 200px;
    padding: 14px 16px;
  }
}

.small-text {
  font-size: smaller;
  font-weight: normal;
}
</style>
