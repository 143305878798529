<template>
  <main>
    <h1>Welcome to TEI'25</h1>
    <p>Cher·e·s toustes, dear attendees, bonjour! </p>

    <p> Welcome to the 19th International Conference on Tangible, Embedded, and Embodied Interaction, hosted at
      Université
      de Bordeaux. We look forward to welcoming you to Bordeaux, France in a few days.</p>

    <p> We are excited to offer a rich and lively conference program, thanks to very high-quality submissions and the
      efforts of our team of organizers and volunteers.</p>
    <p> The detailed conference program can be found on the <a href="https://programs.sigchi.org/tei/2025"
                                                               target="_blank">SIGCHI app</a>.
    </p>
    <h2>Venue and Registration</h2>
    <p> The main conference venue is the <a href="https://maps.app.goo.gl/fYwsfjyxTiwSqYYaA" target="_blank">Domaine du
      Haut Carré</a> , located on the Talence Campus.
      The <a href="https://maps.app.goo.gl/iRs8jZPpSZ1A6aRF8" target="_blank"> studio and GSC venue</a> is a few
      streetcar stops further away. There are different options to collect your badge
      (also summarized <a href="https://tei.acm.org/2025/attend/collect-your-badge" target="_blank"> here </a>):
    </p>
    <ul>
      <li>Delegates registered to attend Studios or the Graduate Student Consortium can register at their respective
        venues on Tuesday March 4th.
      </li>
      <li>Everyone else arriving Tuesday can join us at the <a href="https://maps.app.goo.gl/ggJx9h3875HnpYNJA"
                                                               target="_blank">welcome reception </a> from 6pm to 8pm
        where it will also be
        possible to register.
      </li>
      <li>The general conference registration desk will be open on Wednesday March 5th, from 08:00 AM in the entrance
        hall of the Haut Carré.
      </li>
    </ul>
    <p>Information about all conference venues can be found on the TEI'25 website: <a
        href="https://tei.acm.org/2025/attend/venue" target="_blank">https://tei.acm.org/2025/attend/venue</a></p>

    <h2>Socializing and Networking</h2>
    <p>Every day, there will be opportunities for informal social interactions and networking during breaks and
      dedicated events.</p>
    <ul>
      <li>On Tuesday, the TEI'25 welcome reception will take place at the City Hall of Bordeaux.</li>
      <li>On Wednesday, the diversity lunch will be held at L’alcala restaurant (full, pre-registration was required).
      </li>
      <li>In the evening, we will have a reception around the student design competition at the Domaine du Haut Carré.
      </li>
      <li>On Thursday evening, we will host the Art & Performance and Demos reception at the Bordeaux Museum for
        Contemporary Art (CAPC).
      </li>
      <li>On Friday at 2pm, the TEI Town Hall will be an opportunity to discuss current and future developments for the
        community with members of the TEI Steering Committee.
      </li>
    </ul>
    <p>We also created a Discord server on which all registered participants can ask questions to remote presenters and
      socialize with remote participants.</p>

    <h2>Sustainable TEI</h2>
    <p>We have worked hard to make TEI'25 as sustainable as possible to reduce the conference’s local CO2 emissions.
      Following this year’s theme, in addition to making every effort to provide a positive remote attendance
      experience,
      we will provide local, vegan food with reusable dishes for the conference coffee breaks and dinners. Please note,
      however, that the main conference days do not include lunch this year. You will also receive cardboard badges and
      reusable lanyards, which we kindly ask you to return at the end of the conference.
    </p>

    <h2>Public Transportation</h2>
    <p>
      We provide free 4-day tickets for public transport that you can use with the TBM app. Make sure to check how to
      activate and validate your ticket on the TEI <a href="https://tei.acm.org/2025/attend/public-transportation"
                                                      target="_blank">website</a>. You should have received your code on
      the email address with
      which you registered. Let us know if you did not get it.
    </p>

    <h2>Mobile Internet</h2>
    <p> If you don’t have internet access from your mobile phone in France, you might consider using an eSIM during your
      stay which can be bought online before your trip and which can add data access to phones compatible with eSIMs.
      <a href="https://www.traveltomtom.net/destinations/europe/france/best-esim-cards-for-france#whentoorderanesimforfrance"
         target="_blank">Advice</a> can be found on the Web. If you do not have mobile internet access and your phone is
      not compatible with
      eSIMs, please let us know by Monday.
    </p>

    <h2>ACM Policies</h2>
    <p>
      We want TEI'25 to be an inclusive, supportive, and enjoyable event for all and we ask all attendees to familiarise
      themselves with the <a href="https://www.acm.org/about-acm/policy-against-harassment" target="_blank">policy
      against harassment at ACM events</a> and with the support available through <a
        href="https://sigchi.org/about/sigchi-cares/" target="_blank">SIGCHI CARES</a>.
    </p>

    <h2>Sponsors</h2>
    <p>
      TEI'25 is made possible by a hard-working group of volunteers and supporters and by our generous sponsors: ACM
      SIGCHI, Ville de Bordeaux, Bordeaux Tourisme & Congrès, Région Nouvelle Aquitaine, AFIHM, Bordeaux INP, École des
      Arts Décoratifs, Inria, LaBRI, Inria, Université de Bordeaux, CNRS, ForceDimension, PEPR eNSEMBLE, Immersion, and
      VibraNova.
    </p>

    <br/>
    <p>
      We are grateful to everyone who played a role in making this event possible.</p>

    <p> And, finally, thank you for joining us at TEI'25. We hope you will find valuable opportunities to learn, connect
      with new and familiar colleagues, and engage in thought-provoking interactions over the next few days.</p>

    <p> À très bientôt!
    </p>

    <table class="tg"><tbody>
    <tr>
      <td class="tg-wk8r">Yvonne Jansen</td>
      <td class="tg-wk8r">Céline Coutrix</td>
    </tr>
    <tr>
      <td class="tg-wk8r">TEI'25 General Co-Chair</td>
      <td class="tg-wk8r">TEI'25 General Co-Chair</td>
    </tr>
    <tr>
      <td class="tg-wk8r">CNRS</td>
      <td class="tg-wk8r">CNRS &amp; Université Grenoble Alpes</td>
    </tr>
    <tr>
      <td class="tg-wk8r">France</td>
      <td class="tg-wk8r">France</td>
    </tr>
    </tbody>
    </table>
  </main>

</template>

<script>
export default {
  name: 'WelcomeToTheConferenceView'
}
</script>

<style scoped>
.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:0px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:0px 5px;word-break:normal;}
.tg .tg-wk8r{background-color:#ffffff;border-color:#ffffff;text-align:center;vertical-align:top}
</style>