<template>
  <main>
    <h1>TEI 2025 Accepted Work in Progress</h1>
    <p>We are happy to announce the accepted Work in Progress for TEI25.</p>

    <ul>
      <li><span class="authors">Sarah Matthews, Maria Nicholas, Louise Paatsch, Lisa Kervin, Peta Wyeth</span>	<span class="title">Wobblies: Designing Intentional Playthings with Young Children</span></li>
      <li><span class="authors">Yifan Li, Ryo Takahashi, Wakako Yukita, Kanata Matsutani, Yuhiro Iwamoto, Cedric Caremel, Tomoyuki Yokota, Takao Someya, Yoshihiro Kawahara</span>	<span class="title">Plug-n-play e-knit: prototyping large-area e-textiles using machine-knitted magnetically-repositionable sensor networks</span></li>
      <li><span class="authors">Jon Rogers, Justin Marshall, Jayne Wallace, Tom Metcalfe, Nick Taylor, Romit Raj, Jayn Verkerk, Philip Heslop</span>	<span class="title">pegBits: Experimenting with a 01” system for open hardware prototyping</span></li>
      <li><span class="authors">Hongci Hu, Mengqi Jiang, Kai Lin, Kinor Shou-xiang Jiang, Ziqian Bai</span>	<span class="title">ReKnit-Care: A Seamless-Knitted Sensing Glove for Sensory Rehabilitation and Adaptive Haptic Feedback</span></li>
      <li><span class="authors">Ferran Altarriba Bertran, Jordi Márquez Puig, Jur Jordina Corominas</span>	<span class="title">An In-Progress Research through Design Exploration of the Frictions Emerging in Playful Public Expression Systems (and How to Navigate Them)</span></li>
      <li><span class="authors">Edwina Portocarrero</span>	<span class="title">PillowTalk 2.0: Intuitive Interface for Enhancing Dream Recall Priming and Playback</span></li>
      <li><span class="authors">Hannah van Iterson, Rong-Hao Liang, Panos Markopoulos</span>	<span class="title">Redesigning Fluid Tracking Probes for Elderly Lifestyle Retrofit</span></li>
      <li><span class="authors">Tongxin Sun, Yunying Huang</span>	<span class="title">FlowJam: Augmented Experience Design for Participatory Musical Engagement in Exhibition Spaces</span></li>
      <li><span class="authors">Mona Safari</span>	<span class="title">HiPalm: Wearable Device for Affective Tactile Interpersonal Communication</span></li>
      <li><span class="authors">Tomoka Kurosawa, Takafumi Morita, Kanon Aoyama, Shingo Maeda, Yasuaki Kakehi</span>	<span class="title">Controlling the Direction of Colored Liquid Flow at Branching Junctions with Fewer Actuators</span></li>
      <li><span class="authors">Leticia Izquierdo Garcia, Quentin Bolsee</span>	<span class="title">ORILUM: Exploring the Fabrication Techniques of Kinetic Origami Tactile Interactive Artifacts</span></li>
      <li><span class="authors">Gianni Tumedei, Chiara Ceccarini, Catia Prandi</span>	<span class="title">How are you, Mar Menor? Fostering Awareness About an Ecological Crisis through Children's Art and Conversational Generative AI</span></li>
      <li><span class="authors">Katarzyna Wojna Nowak, Anna Kubczak, Julia Dominiak, Michael Wright</span>	<span class="title">Warm! Warmer! Hot! - Leveraging Thermal Technology to Foster Social Interaction with WarmConnect</span></li>
      <li><span class="authors">Ethan Chang, Zhixing Chen, JB Labrune, Marcelo Coelho</span>	<span class="title">Be the Beat: AI-Powered Boombox for Music Generation from Freestyle Dance</span></li>
      <li><span class="authors">Afroza Sultana, Stacy Cernova, Megan Wang, May Yu, Wenyue Zheng, Yin Yifan, Tudor Tibu, Alexander Bakogeorge, Aneesh P. Tarun, Ali Mazalek</span>	<span class="title">Designing a Co-located Collaborative Cross-device Game for Ad Hoc Social Settings</span></li>
      <li><span class="authors">Sarah Matthews, Maria Nicholas, Louise Paatsch, Lisa Kervin, Peta Wyeth</span>	<span class="title">Embeddables: Designing Physical-Digital Manipulatives for Young Children</span></li>
      <li><span class="authors">Yağmur Kocaman, Oğuzhan Özcan</span>	<span class="title">Pick your Foodiebuddy: Travelers’ Reflection on a Personal Taste Exploration Gadget</span></li>
      <li><span class="authors">Eleni Economidou</span>	<span class="title">This Is How I Felt: Crafting Conductive Wool Artefacts as an Embodied Practice to Regulate Anxiety</span></li>
      <li><span class="authors">Anna Walczak, Julia Dominiak, Mikołaj P Woźniak, Wojciech Walczak, Krzysztof Grudzień</span>	<span class="title">ExpreSense: Designing Interactive Necklace to Support Identity Expression Using Biometric and Ambient Feedback</span></li>
      <li><span class="authors">Senna van den Bogaard, Timo Maessen, Evelijn van Hilten, Yinshi Jin, Hannah van Iterson, Steven Houben, Rong-Hao Liang</span>	<span class="title">CrowdCollab: A Multi-user, Multi-perspective Collaborative Tool for Crowd Planning</span></li>
      <li><span class="authors">Yechen Zhu</span>	<span class="title">Cultivating a green interface - Exploring the potential of human-product interaction based on plant interfaces</span></li>
      <li><span class="authors">Karen Pauwels, Laura Herrewijn, Lowie Spriet, Jeroen Cluckers, Keerthanan Vignarajah, Florian Stigter Van Thillo, Rob Mertens, Lara Hallam, Andi Smet, Silvia Van Aken</span>	<span class="title">“Antwerp Under Water”: The Interdisciplinary Human-Centered Design of an Embodied VR Experience on Climate Change</span></li>
      <li><span class="authors">Aryan Saini, Sabari VS, Maria Fernanda Montoya, Nathalie Overdevest MSc, Rakesh Patibanda, Don Samitha Elvitigala, Elise van den Hoven, Florian ‘Floyd’ Mueller</span>	<span class="title">Inflated Exertion: Designing a Bodily Extension that Embodies Physical Activity</span></li>
      <li><span class="authors">Xiaoyu Wang, Hannah van Iterson, S  Sandra Bae, Rong-Hao Liang</span>	<span class="title">Reflecting on Solo Dining Behavior with Annotated Data Physicalization</span></li>
      <li><span class="authors">Emilie Clément, Raphaëlle C  Bats, Yvonne Jansen, Mathilde Garnier</span>	<span class="title">From Data to Data Games: Exploring Public Engagement with Scientific Information through Serious Game Design Workshops</span></li>
      <li><span class="authors">Stephan Huber, Tamara Friedenberger, Parzival Borlinghaus, Sara Wolf</span>	<span class="title">Wax Arts With Honeybees – Taking First Steps Toward Multispecies Co-Creation</span></li>
      <li><span class="authors">Antoni Rayzhekov, Martin Murer</span>	<span class="title">The Well-Tempered Objects: Using Everyday Things for Tangible Composition</span></li>
      <li><span class="authors">Carlos Alberto Granados Ybarra, Katherine Jimena De la Cruz, Mayli Alejandra Tafur Gutierrez, Miguel A Campos, Manuel Antonio Chavez Salinas</span>	<span class="title">PlantiBot: Towards the Design of a Robotic Plant for Mental Health Care</span></li>
      <li><span class="authors">Khushbu Kshirsagar, Ashley S Quiterio, Michael Smith</span>	<span class="title">MorphBlox: Bridging Play and Mathematics through Kinetic Tessellations</span></li>
      <li><span class="authors">Kristof Timmerman, Laura Herrewijn, Jeroen Cluckers, Andi Smet, Lowie Spriet</span>	<span class="title">The Portal as a Key Element to Embodied Storytelling</span></li>
      <li><span class="authors">Krithik Ranjan, Ann Eisenberg, Brett L Fiedler, Jesse Greenberg, Taliesin L  Smith, Emily B Moore</span>	<span class="title">Physical Computing with Paper Playground: Exploring a Multimodal Platform</span></li>
      <li><span class="authors">Giampiero Bartolomei, Beste Ozcan, Giovanni Granato, Gianluca Baldassarre, valerio sperati</span>	<span class="title">Echo: an AI-based toy to encourage symbolic play in children with Autism Spectrum Conditions</span></li>
      <li><span class="authors">Ziwen Yan, Xuanxuan Liu, JB Labrune, Marcelo Coelho</span>	<span class="title">Colorimia: Color picking and image generation with a physical AI</span></li>
      <li><span class="authors">Aven-Le Zhou, Lei Xi, Kang Zhang</span>	<span class="title">Mixed or Misperceived Reality? Flusserian Media Freedom through Surreal Me</span></li>
      <li><span class="authors">Leon Lange, Jacob Yenney, Ying Choon Wu</span>	<span class="title">Bridging Psychedelic VR and BCI: Enhancing User Experience through Adaptive EEG-Guided Neural Modulation</span></li>
      <li><span class="authors">Sabrina Panëels, Charles Hudin, Ayoub Ben Dhiab</span>	<span class="title">Exploring Textured Communication with Multitouch Capture and Playback of Tactile Interactions on Surfaces</span></li>
      <li><span class="authors">Enza Migliore, Yaohan Zhang, Yujing Ma</span>	<span class="title">Future Archeology: Boosting Cultural Vibrancy and Future Memories through AI Regenerated Materialities in Co-Designed Virtual Spaces</span></li>
      <li><span class="authors">Zita Willems, Louise Cuypers, Ingmar Ivo P. Malfait, Luc Geurts</span>	<span class="title">A Juicy Haptics Enhanced Keyboard for a Touch-Typing Course for Learning Disabled Children</span></li>
      <li><span class="authors">Mohsen Ensafjoo, Chau Nguyen, Roozbeh Manshaei, Ali Mazalek</span>	<span class="title">Exploring Approaches for Shape-Shifting Tangible Interfaces</span></li>
      <li><span class="authors">Bolor Amgalan</span>	<span class="title">Virtual Reality Materials as Creativity Support Tools</span></li>
      <li><span class="authors">Maximilian Krieger, Madalina Nicolae, Jürgen Steimle</span>	<span class="title">Living Layers: Designing Modular E-Skin with Bacterial Cellulose</span></li>
      <li><span class="authors">Ádám Szabó</span>	<span class="title">Opening black boxes: A user-centered approach to repair electronic household products</span></li>
      <li><span class="authors">Merel A Lieshout van, Miguel Bruns</span>	<span class="title">Doughnamics: Reproducing the experience of kneading dough through the design of a haptic interface</span></li>

    </ul>
  </main>
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
