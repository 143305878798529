<template>
  <main>
    <h1>Work-In-Progress</h1>

    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Full submission deadline
        </td>
        <td>
          <span style="text-decoration: line-through">October 16, 2024, AoE</span><br>
          October 21, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of acceptance
        </td>
        <td>
          November 23, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          December 4, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>


    <h2>General Information</h2>
    <p>
    The Work-in-Progress track in TEI2025 is the venue for presenting cutting-edge findings, front-line innovations, or thought-provoking work that is relevant to the TEI community, and truly in progress. Work-in-Progress provides a unique opportunity to share ideas, elicit feedback on early-stage work, and foster discussions and collaborations among colleagues.
    </p>
    <p>
    We encourage researchers and practitioners to submit intermediate reports on high-potential, original, imaginative research projects. We welcome promising results, early prototypes, inspiring problems and ideas, puzzling research data, outstanding problems, horizons, and conceptual analyses that are all grounded in solid – yet unfinished – research and design work. We encourage tangible, embedded, and embodied interaction projects that, although in progress today, promise to become hotly debated breakthroughs in the future.
    </p>

    <h2>Contribution types</h2>
    <p>
    Work-in-Progress submissions should present high-quality original work relevant to the TEI community. Topics and application areas are diverse, including tangible user interfaces, physical interaction design, flexible and shape-changing displays, haptic interaction, smart objects and environments, interactive surfaces, augmented and mixed reality, ubiquitous computing, interactive art and performance, social and wearable robotics, hybrid games, embodied cognition and perception, fashion and material design, furniture and architectural design, learning and education, music and sound interfaces, human-augmentation, as well as productivity and creativity tools in domains ranging from scientific exploration to artistic practice.
    </p>
    <p>
    Accepted submissions will be included in the ACM Digital Library Extended Proceedings (i.e., non-archival publications).
    </p>

    <h2>Submission guidelines</h2>
    <p>
    Submissions consist of a paper and an optional video. Upon acceptance, authors will be asked to provide a publication-ready copy that includes this information, along with any recommended improvements as suggested by the reviewers. Details for each process stage are indicated below (submissions for review and presentation material to submit after the paper is accepted).
    </p>

    <b>MATERIAL TO SUBMIT FOR REVIEW</b>
    <p>
    Submissions should be up to 8 pages long in the <a href="https://www.acm.org/publications/authors/submissions">ACM Primary Article Template</a> (single column submission format, same as full papers), not including references. Submissions are anonymous and should not include any author names, affiliations, and contact information.
    </p>

    <b>SUPPORTING MATERIALS FOR PRESENTATION</b>
    <p>
    Given the emphasis on tangible interaction at TEI, the paper should explicitly reference (at least) one of the following tangible expressions that you can eventually bring to the conference for presentation. More than one of these expressions is possible, and you can choose a format that best represents your current thinking and recent insights:
    </p>
    <ul>
      <li>
        A physical prototype/demo (provide pictures in the paper);
      </li>
      <li>
        Audiovisual footage of user testing and/or ethnographic materials (provide link or video stills in the paper);
      </li>
      <li>
        A visualization of a pivotal diagram, model, or framework you are developing, possibly with earlier versions to show its evolution (provide link to hi-res version, prezi, or visual animation if so needed);
      </li>
      <li>
        Design-research probes or other kinds of tangible objects, toolkits, materials, research equipment that significantly contributed to your work and which you can physically bring and display at TEI (provide pictures in paper);
      </li>
      <li>
        A video of your design/research process may help communicate your concept (if included, please provide a link in the paper).
      </li>
    </ul>

    <b>SUBMISSION AND REVIEW PROCESS</b>
    <p>
      All submissions must be made via <a href="https://new.precisionconference.com">Precision Conference (PCS)</a>.
    </p>
    <p>
    Submissions will be reviewed in a double-blind process by two reviewers (one committee member and one external reviewer), and a final decision will be consolidated with a meta-review after a discussion among the committee members.
    </p>
    <p>
    Work-in-Progress submissions will be reviewed based on the following criteria:
    </p>
    <ul>
      <li>
        The work is truly ‘in-progress’;
      </li>
      <li>
        The work presents a ‘tangible expression of the research’ that can be physically displayed at the conference;
      </li>
      <li>
        Relevance for conference theme;
      </li>
      <li>
        Overall quality (given a Work-in-Progress: potential impact, research/design approach);
      </li>
      <li>
        Uniqueness (innovativeness, added value);
      </li>
      <li>
        Potential to enrich and provoke discussion.
      </li>
    </ul>

    <b>PRESENTATION FORMAT</b>
    <p>
    All accepted submissions will showcase their WiP paper as an A1 poster (594mm x 841mm), together with the tangible expression (prototype, video, probes, etc.) at the TEI conference. You are encouraged to include a barcode or tag in your poster to support mobile access to online versions and auxiliary materials for your work. Note that space is limited. Your tangible device can be displayed in the space in front of the poster. No tables are guaranteed, but we can ensure electric power and Wi-Fi.
    </p>

    <b>CONFIDENTIALITY</b>
    <p>
    Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity. All submitted materials for accepted submissions will be kept confidential until the start of the conference.
    </p>

    <b>ATTENDANCE</b>
    <p>
    One author of each accepted submission must register for the conference by the early registration deadline for the final Work-in-Progress paper to be published in the conference proceedings.
    </p>

    <h2>Examples of previous work-in-progress</h2>
    <p>Work-in-Progress papers from TEI 2023 and 2024:</p>
    <ul>
      <li>
        Jules Sinsel, Anniek Jansen, and Sara Colombo. 2023. <a href="https://dl.acm.org/doi/10.1145/3569009.3573116">Facebook Data Shield: Increasing Awareness and Control over Data used by Newsfeed-Generating Algorithms.</a> DOI: 10.1145/3569009.3573116
      </li>
      <li>
        Xinyi Yang, Susanna Chen, Katarina Bulovic, Junyi Zhu, and Stefanie Mueller. 2023. <a href="https://dl.acm.org/doi/10.1145/3569009.3573107">Azimuth Calculation and Telecommunication between VR Headset and Smartphones for Nearby Interaction.</a> DOI: 10.1145/3569009.357310
      </li>
      <li>
        Konrad Fabian, Dennis Wittchen, and Paul Strohmeier. 2024. <a href="https://dl.acm.org/doi/10.1145/3623509.3635249">3D-Printed Cells for Creating Variable Softness.</a> DOI: 10.1145/3623509.3635249
      </li>
      <li>
        Kaja Seraphina Elisa Hano and Valkyrie Savage. 2024. <a href="https://dl.acm.org/doi/10.1145/3623509.3635257">Hybrid Crochet: Exploring Integrating Digitally-Fabricated and Electronic Materials with Crochet.</a> DOI: 10.1145/3623509.3635257
      </li>
    </ul>


    <h2>Work-In-Progress Chairs</h2>
    Lucca Geurts, <i>KU Leuven</i>, Belgium<br>
    Amy Melniczuk, <i>Northumbria University</i>, UK<br>
    <br>
    For further questions please contact the Work-In-Progress chairs at <a href="mailto:wip_chairs@tei.acm.org">wip_chairs@tei.acm.org</a>

  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}
</style>
