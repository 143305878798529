<template>
  <main>
    <h1>TEI 2025 Accepted Studios</h1>
    <p>We are happy to announce the accepted Studios for TEI25.</p>
    <p>Studios have always played a major role in TEI conferences, and we want this year to be no different. Twelve unique Studios at TEI 2025 will provide new and enriching perspectives on the field of tangible, embedded, and embodied interaction by combining the hands-on with the academic. Studios will take place in the campus <a href="https://www.eirlab.net/">FabLab - EirLab</a> on Tuesday March 4. The exact timing and planning of each Studio will be decided by its organizers, and shared below. For most Studios, you can simply sign up when registering for the conference, though some Studios require participant submissions. See the Studio descriptions to learn more.</p>

    <ul>
      <li v-for="studio of acceptedStudios" :key="studio.title">
        <span class="title">{{studio.title}}</span> <br>
        <span class="authors"><b>Organisers:</b> {{studio.authors}}</span><br>
        <span class="duration"><b>Duration:</b> {{studio.duration}}</span><br>
        <span class="participants"><b>Maximum number of participants:</b> {{studio.participants}}</span><br>
        <span class="description"><b>Description:</b> <span v-html="studio.description"/></span><br>
        <span class="website"><b>Link to studio website:</b> <a :href="studio.links[0]">{{studio.links[0]}}</a></span><br>
        <span class="contacts"><b>For more information, please contact: </b>
          <template v-for="[i, contact] of studio.contacts.entries()">
            <a :href="`mailto:${contact}`">{{contact}}</a>
            <span v-if="i !== studio.contacts.length - 1">, </span>
          </template>
        </span>
      </li>
    </ul>
  </main>
</template>

<script setup>
const acceptedStudios = [
  {
    title: "Embodied Ideation, Toolkits, and Sketching",
    authors: "José Manuel Vega-Cebrián (Universidad Carlos III de Madrid), Andreas Lindegren ( KTH Royal Institute of Technology), Mafalda Gamboa (University of Technology Gothenburg), Ana Tajadura-Jiménez (Universidad Carlos III de Madrid & UCL Interaction Centre), Ylva Fernaeus ( Umeå Institute of Design and KTH Royal Institute of Technology), Elena Márquez Segura (Universidad Carlos III de Madrid)",
    duration: "From 9:00 to 17:30",
    participants: "30",
    description: " Movement-based design foregrounds the moving and sentient body, fostering holistic engagement with the surrounding physical, material, and sociospatial contexts. Over the years, this approach has yielded multiple methodologies, tools, and exemplars to support body-based ideation. In this studio, we explore tools that facilitate embodied thinking and the creative processes of designing with, through, and for the body. In particular, we focus on: i) embodied ideation tools, kits, and technology probes to prompt ideation; ii) sketching and other documentation techniques to materialize ephemeral embodied action during ideation. We will bring tools and techniques to engage with both aspects, and we will invite participants to bring their own, which can be physical or technological, low or high-fidelity. This hands-on studio will provide a space to collectively engage in embodied ideation and sketching; exploring, analysing, and engaging deeply with the available objects and methods. The studio will culminate in a rich set of visual material and an annotated portfolio, which will be shared with the broader community, fostering connections among designers interested in movement-based and tangible design.",
    links: ["https://sites.google.com/view/embodiedsketching/"],
    contacts: ["jovegac@inf.uc3m.es"]
  },
  {
    title: "Sensoaesthetics: Introducing alternative embodied material expressions in textile and fashion",
    authors: "Vidmina Stasiulytė (University of Borås), Emilie Giles (Arts University Bournemouth), Anke Brock (ENAC, Toulouse), Christophe Jouffrais (IRIT, Toulouse)",
    duration: "From 9:00 to 17:00",
    participants: "20 (plus 5 invited people with a visual impairment and their assistants)",
    description: "You are invited to join us in a mixed group of researchers and participants with visual disability for an in-person TEI studio exploring the senses through materials, objects and vocabularies. Participants will work in groups to create their own ‘sonic prototype’, which explores how we can move towards fashion being focused on auditory qualities. From the workshop, we aim for participants to be introduced to a more experimental approach, which involves inclusive aesthetics for designing and representing textile and fashion artifacts. We intend to expand vocabulary around material definitions in a collaborative and inclusive way. You are invited to dive into the sonic dimension and explore sound embodiment and sonic interactions. WELCOME!!!",
    links: ["https://vidmina.wixsite.com/sensoaesthetics"],
    contacts: ["vidmina.stasiulyte@hb.se", "egiles@aub.ac.uk"]
  },
  {
    title: "Exploring the Design Process of Odd Interactions through the example of Full-Body Textile Interfaces",
    authors: "SARA MLAKAR (University of Applied Sciences Upper Austria and TU Wien) and KATHRIN PROBST (University of Applied Sciences Upper Austria, Austria)",
    duration: "From 9.30 – 17.00",
    participants: "12",
    description: "We will introduce participants to the emerging design practice of Odd Interactions through the example of full-body interactions with smart textile interfaces. As technology advances, the need for diverse interaction modalities, beyond traditional surface gestures, is increasingly important. Full-body interactions, in particular, remain a less explored area. By employing the approach of Odd Interactions, we aim to expand this design space and uncover novel interaction possibilities. Participants will engage in exercises that explore odd body parts, odd techniques, odd responses, and odd contexts to develop interactive textile artifacts. The studio's key outcomes are the artifacts themselves, their innovative interaction possibilities, and potential use case applications. Participants will become familiar with the design practice of Odd Interactions and engage in a hands-on exploration of full-body interactions with textiles.",
    links: ["https://sites.google.com/view/odd-interactions/tei25"],
    contacts: ["sara.mlakar@fh-hagenberg.at", "Kathrin.Probst@fh-hagenberg.at"],
  },
  {
    title: "Sensory Data Dialogues: A Somaesthetic Exploration of Bordeaux through Five Senses",
    authors: "Fiona Bell (University of New Mexico), Karen Anne Cochrane (University of Waterloo), Alice C. Haynes (KTH Royal Institute of Technology), Marion Koelle (OFFIS – Institute for Information Technology), Courtney N. Reed (Loughborough University London), Alexandra Teixeira Riggs Georgia Institute of Technology), Laia Turmo Vidal (KTH Royal Institute of Technology), L. Vineetha Rallabandi (Max Planck Institute for Informatics)",
    duration: "From 9am to 5pm",
    participants: "20",
    description: "Join us in a hands-on studio through a walking tour of Bordeaux, where data merges with creative embodied methods to explore and document sensory experiences. Using sensors to measure bodily and environmental phenomena such as heart rate and air quality, alongside actuators like vibration and light devices, and qualitative somaesthetic methods such as body mapping and written reflections, participants will capture and reflect on sensory data across sight, touch, taste, sound, and smell. Together, we’ll uncover how these experiences shape meaning-making and ecological awareness as we explore the city of Bordeaux and site-specific sensory experiences.\n" +
      "Designed for HCI practitioners, designers, artists, and researchers, this workshop investigates how technology and embodied practices can redefine our understanding of sensory data. We will enjoy the city as we reflect on how environments and methods themselves co-create our experiences. Participants will leave with a personalized Sensory Portfolio, actionable methods, and fresh perspectives to integrate into their work. Discover the transformative potential of open-source electronics, somatic data collection, and design innovation!<br>" +
      "<br>" +
      "Please note: this Studio will take place outside the main conference venue in the city and chosen locations for the activities and will include about 1.5hrs of walking spread over the 8-hour Studio Please see the Studio proposal document for further information and feel free to get in touch with any questions on the logistics.",
    links: ["http://uwaterloo.ca/stratford/sensory-workshop"],
    contacts: ["karen.cochrane@uwaterloo.ca"],
  },
  {
    title: "Tangible LLMs: Tangible Sense-Making For Trustworthy Large Language Models",
    authors: "Leonardo Angelini (HES-SO//Fribourg), Goda Klumbyte (University of Kassel), Maxime Daniel (ESTIA-Institute of Technology), Nadine Couture (ESTIA-Institute of Technology), Beat Wolf (HES-SO//Fribourg), Elena Mugellini (HES-SO//Fribourg), Claude Draude (University of Kassel)",
    duration: "From 9am to 5pm",
    participants: "20",
    description: "This studio explores the potential of tangible AI -- giving physical form to Artificial Intelligence -- to enhance understanding and trust in transformer-based models like Large Language Models (LLMs).  Through hands-on activities, we will investigate how tangible interaction techniques can demystify core LLM mechanisms like input/output embedding, positional encoding, and self-attention. Participants will engage in brainstorming and prototyping sessions to design tangible interfaces that help users grasp how LLMs make predictions.",
    links: ["https://sites.google.com/view/tangiblellms/home"],
    contacts: ['leonardo.angelini@hefr.ch', 'goda.klumbyte@uni-kassel.de', 'm.daniel@estia.fr'],
  },
  {
    title: "Designing for and with intimate (sexual) bodies - Towards feminist and queer somatic understandings of pleasure",
    authors: "Silvia Teisanu, Nadia Campo Woytuk, Joo Young Park, Anna Brynskov, Dianya Mia Hua, Marianela Ciolfi Felice, Saúl Baeza Argüello, Oscar Tomico, Madeline Balaam",
    duration: "From  9:00 – 17:00",
    participants: "15",
    description: "This studio explores methods for prototyping with and for intimate and sexual experiences, focusing on a diversity of bodies and understandings of pleasure. We aim to both explore how to communicate people’s somatic (sexual) profiles while engaging in creative making processes. Participants will engage in a full day workshop consisting of three distinct phases: trying out methods for representing somatic experiences, tangible ideation and making, and group reflections. Through these activities, we not only aim to enhance individual awareness of erotic bodies but also share advice and experiences on how to design for sexual experiences from feminist and intersectional perspectives. This studio seeks to promote inclusivity and challenge normative beliefs about sexual bodies, designing for intimacy and contributing to a more equitable discourse on pleasure.",
    links: ["https://intimatebodies.carrd.co/"],
    contacts: ["s.teisanu@student.tue.nl"],
  },
  {
    title: "Leveraging the “Superpowers” of Engineered Living Materials to Craft Soft Living Artefacts",
    authors: "Giorgia Burzio (Politecnico di Milano), Venere Ferraro (Politecnico di Milano), Neel S Joshi (Northeastern University), Valeria Regis (Politecnico di Milano)",
    duration: "From 9am to 5pm",
    participants: "18",
    description: "We invite participants from Design and Human-Computer Interaction fields to an interdisciplinary Studio exploring the potential of Engineered Living Materials (ELMs) to design Living Artefacts. This workshop offers the opportunity to speculate with augmented silicone, material embedded with life-like properties such as self-regeneration and environmental responsiveness. <br>" +
      "Participants will explore how these materials can inspire new design possibilities for three interactive artefacts closely in touch with bodies: a menstrual cup, a breast pump, and a sex toy. The Studio will guide them in identifying the material’s unique “superpowers” and examining how its distinctive properties can address usability challenges and enhance interaction design, while engaging in reflections on the ethical, social, and interdisciplinary challenges when designing with and for livingness.  <br>" +
      "Join this Studio to explore first-hand the opportunities and challenges of designing Living Artefacts through ELMs!",
    links: ["https://softlivingartefacts-tei25.cargo.site/"],
    contacts: ["giorgia.burzio@polimi.it", "valeria.regis@polimi.it"],
  },
  {
    title: "Shape-change in Keyboard Interaction: Exploring the Future of Input Devices Through Prototyping",
    authors: "Laura Pruszko (Glasgow Caledonian University), Adrien Chaffangeon Caillet (Grenoble INP - Université Grenoble Alpes), Zhuzhi Fan (University of Bristol)",
    duration: "From 9:00 to 16:00",
    participants: "20",
    description: "This full-day studio explores the intersection of shape-changing technology and keyboard interaction. We invite HCI researchers, interaction designers, and accessibility specialists to prototype and reflect on the future of input devices. Participants will be introduced to key concepts in shape-changing interfaces and methodologies before engaging in hands-on, sustainable prototyping activities, using ideation tools and quick-build materials to explore how shape-changing interfaces can reshape the way we interact with keyboards. The studio will foster a collaborative environment where participants from diverse disciplines can experiment with non-expert prototyping methods and reflect on the role of keyboards in tangible interaction design.",
    links: ["https://sites.google.com/view/shape-change-keyboard"],
    contacts: ["laura.pruszko@gcu.ac.uk"],
  },
  {
    title: "Making Sense of Decentralized Tangible Interfaces: A Modular Sound-Centric Approach",
    authors: "Francesco Di Maggio (Eindhoven University of Technology), Bart Hengeveld (Eindhoven University of Technology), Mathias Funk (Eindhoven University of Technology)",
    duration: " From 9:00 to 17:00",
    participants: "10",
    description: "In this hands-on studio, we invite researchers, designers, technologists, and makers to explore how tangible interfaces can form interconnected, sound-centric smart ecologies. Participants will engage with a modular toolkit to create responsive soundscapes that adapt and evolve to their environments. Drawing from the practices of digital lutherie and sound design, we will experiment with interactive machine learning techniques to map sensor data to expressive sound interactions. <br>" +
      "Participants will:" +
      "<ul><li> Learn how to create responsive soundscapes by integrating sound as a core design modality.</li>" +
      "<li> Prototype adaptive systems, mapping sensor data to expressive sound interactions.</li>" +
      "<li> Gain hands-on experience with interactive machine learning techniques.</li>" +
      "<li> Explore sustainable design practices based on modular approaches.</li></ul><br>" +
      "We will collectively design a decentralized, sound-centric, networked system. We welcome anyone with an interest in sustainable design, tangible and embodied interaction, and sound. Join us at TEI’25 to reimagine how our smart objects can speak to us—and to each other.",
    links: ["https://sites.google.com/view/decentralized-sound-tuis"],
    contacts: ["f.di.maggio@tue.nl", "b.j.hengeveld@tue.nl"],
  },
  {
    title: "Touching history: Designing digital papyrus interactions through physical interface",
    authors: "Robin Cherix (HES-SO, Switzerland), Marine Capallera (HES-SO, Switzerland), Omar Abou Khaled (HES-SO, Switzerland), Isabelle Marthot-Santaniello (Universität Basel)",
    duration: "From 13h00 to 16h00",
    participants: "10",
    description: "Come explore innovative ways to interact with cultural heritage with us! In this Studio, “Touching History: Designing Digital Papyrus Interactions through Physical Interface,” we will present our project “Papy3D,” which aims to recreate the experience of interacting with ancient Egyptian papyri. Leveraging recent advances in extended reality (XR) and digital twin technologies, we can now design and develop interactive, lifelike replicas of these historical artifacts. In this Studio, participants will not only have the opportunity to engage with a physical scroll of papyrus but also imagine and prototype how it can be used to intuitively interact with a 3D agent in the form of a digital twin within an XR environment. This Studio invites you to explore and design multimodal interactions that bridge the physical and digital realms of ancient history. This project is at destination of both scholars and general public in the museums.",
    links: ["https://papy3d.humantech.institute/studio-tei-25/"],
    contacts: ["robin.cherix@hes-so.ch"],
  },
  {
    title: "Interactive Waste",
    authors: "Sara Nevay (Duncan of Jordanstone College of Art & Design, University of Dundee), Lucy Robertson Nevay (Heriot-Watt University), Chris Lim (Duncan of Jordanstone College of Art & Design, University of Dundee)",
    duration: "From 09h00 to 17h00",
    participants: "10",
    description: "Calling interactive textile researchers, designers and intrigued colleagues!<br>" +
      "Interactive Waste is a day-long studio that will explore issues of sustainability around e- and interactive textile making. Together we’ll consider how we might respond to issues of waste, and support more sustainable future making.<br>" +
      "In groups, this studio invites you to share examples of your own interactive textiles (as we will from ours), before: 1) ‘deconstructing’ them – if not literally, through sketching - to create ‘recipes’ that reflect the ingredients, method and potential impact, and 2) ‘remaking’ them from a stock of waste or reclaimed prototyping materials within the studio to produce alternative and sustainable recipes for making future interactive textiles. By the close of the studio, we hope to have brought together an active community aimed at countering waste within practice and to have created space for rich discussion, reflection, and future action.",
    links: [],
    contacts: ["s.z.nevay@dundee.ac.uk"],
  },
  {
    title: "WireShape Studio: Exploring the Design and Creation of Inflatables using a Low-Cost, Rapid Prototyping Process",
    authors: "Hannes Waldschütz (Bauhaus-Universität Weimar), Rosa van Koenigsbruggen (Bauhaus-Universität Weimar), Eva Hornecker (Bauhaus-Universität Weimar)",
    duration: "From 09h00 to 16h30",
    participants: "12",
    description: "In this studio, we will explore the potential of inflatables for tangible interfaces by designing and fabricating inflatable props using a highly versatile and low-cost technique. The WireShape prototyping process can be used by researchers, makers, designers, and artists for the rapid and reliable manufacturing of inflatables.We introduce an accessible method for creating inflatable user interface elements using hand-made heat-sealing tools made from aluminum wire, applied to tpu-coated fabrics using a widely available T-shirt heat press. The resulting inflatable objects can be used as tangible artifacts for design and experience prototyping, or to produce research prototypes.<br><br>" +
      "The workshop will introduce participants to this method, focusing on the practical aspects of creating inflatables, and will enable participants to explore the potential of inflatables for unique and engaging interactions in the context of interaction design and data physicalisation. Through hands-on activities, we will demonstrate how to sketch and create inflatables for tangible interfaces and discuss their benefits, limitations, and the possible integration of sensor technology.",
    links: ["http://www.wireshapestudio.hnnz.de/"],
    contacts: ["h.waldschuetz@uni-weimar.de"],
  },
  // {
  //   title: "Embodied Ideation, Toolkits, and Sketching",
  //   authors: "",
  //   duration: "",
  //   participants: "",
  //   description: "",
  //   links: [],
  //   contacts: [],
  // }
  // {
  //   title: "",
  //   authors: "",
  //   duration: "",
  //   participants: "",
  //   description: "",
  //   links: [],
  //   contacts: [],
  // }
]
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
  color: var(--accent-hard);
}

li {
  margin-bottom: 20px;
}
</style>
