<template>
<div class="footer">
  <div class="break-line"/>
  <div class="infos">
    <h2>Conference Information</h2>
    <img src="@/assets/tei_logo_small.svg">
    <p>04-07 March 2025, Bordeaux, France</p>
    <p>19th International Conference on Tangible Embedded and Embodied Interaction</p>
  </div>
</div>
</template>

<script setup>
</script>

<style scoped>
.break-line {
  width: 80vw;
  height: 4px;
  background: var(--textlight);
  grid-column: span 3;
  justify-self: center;
  border-radius: 5px;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--background);
  color: var(--text);
}

img {
  height: 40px;
  margin-right: 15px;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 90%;
}
</style>
