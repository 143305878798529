<template>
  <main>
    <h1>Pick Up Your Badge!</h1>
    <p>
      The registration desk for the conference are open from 8am to 8.30am in the <a href="./venue"> Agora du
      Haut-carré</a>. Some students volunteers will be on hand at the registration desk outside this period, but expect
      delays in collecting your badge.
    </p>
    <p>
      On Tuesday, for studios (except the one taking place in Campus Bordeaux Pey-Berland) and doctorial consortium attendees, the registration booth will be open from 8am to 10am in <a href="./venue"> Enseirb - Matmeca</a>.<br/>
      For the "Sensory Data Dialogues" studio in the Campus Bordeaux Pey-Berland, the registration desk will be open from 8am to 12.30pm. (only for "Sensory Data Dialogues" attendees)
    </p>

  </main>
</template>

<script>
export default {
  name: 'CollectYourBadgeView'
}
</script>

<style scoped>

</style>