<template>
  <main>
    <h1>Instructions for presenters</h1>
    <!--  <h2>In-person presenters</h2>-->
    <p> Please prepare a talk which is no longer than 10 minutes. </p>

    <p>Please arrive at your session 20 minutes early. Use this time to meet your session chair and double-check that your
    presentation is working as intended.</p>

    <p>Each presenter will be allotted a 15 minute time slot, of which <b>up to 10 minutes</b> can be used for your talk.
    The rest of the time is intended for discussion. We suggest keeping talks short, so that there is time for actual
    discussion and debate. Please coordinate with your session chair what type of questions would help for a meaningful
    discussion, and if you have any additional material or backup slides prepared, so the session chair can better guide
    the discussion.</p>

    <p> Please double-check your session here: <a href="https://programs.sigchi.org/tei/2025" target="_blank">https://programs.sigchi.org/tei/2025</a>.</p>

    <p>Session chairs will be announced in the days leading up to the conference. </p>
  </main>
</template>

<script>
export default {
  name: 'PresentersInstructions'
}
</script>

<style scoped>

</style>