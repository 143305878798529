<template>
  <main>
    <h1>TEI 2025 Accepted Pictorials</h1>
    <p>We are happy to announce the accepted Pictorials for TEI25.</p>
<ul>
    <li><span class="authors">Fiona Bell, Camila Friedman-Gerlicz, Leah Buechley</span>	<span class="title"> Biomaterial Recipes for 3D Printing: A Cookbook of Sustainable and Extrudable Bio-Paste.</span></li>
    <li><span class="authors">Kuntong Han, Keyang Tang, Meng Wang</span>	<span class="title"> Stage Wizard: Enhancing Tangible Storytelling with Multimodal LLM.</span></li>
    <li><span class="authors">Beatrice Maggipinto, Yanick Trindade, Jessica Hammer, Nuno Jardim Nunes, Valentina Nisi</span>	<span class="title"> Echo of the Abyss: Understanding More-than-human Interconnectedness in the Deep Sea Through Virtual Reality Experiences.</span></li>
    <li><span class="authors">Anna My Bertmark, Mathilde Gouin, Nuno Jardim Nunes, David E Millard, Pedro Ferreira, Vera Karina Gebhardt Fearns, Marta Galvão Ferreira, Valentina Nisi</span>	<span class="title"> Natureculture Probes: Opening up dialogues in natural heritage(s) landscape.</span></li>
    <li><span class="authors">Nicole Zizzi, Laura J Perovich</span>	<span class="title"> A framework for body and movement-based data representation.</span></li>
    <li><span class="authors">Salma Ibrahim, Sara Nabil</span>	<span class="title"> E-Serging: Exploring the Use of Overlockers (Sergers) in Creating E-Textile Seams and Interactive Yarns for Garment Making, Embroidery, and Weavin.</span></li>
    <li><span class="authors">Romain Toebosch, Arne Berger, Carine Lallemand</span>	<span class="title"> Challenging Sensor-based Personal Informatics: A Triadic Framework for Designing Open-ended Self-tracking Device.</span></li>
    <li><span class="authors">Sarah Hayes, Martin Lindrup, Kim Sauvé, Denise Heffernan, Nathalie Bressa, Rosa van Koningsbruggen, Lisa Zimmermann, Samuel Huron</span>	<span class="title"> Travel Patterns & Conference Intentions: Engaging Conference Attendees with Sustainability through Input Physicalizatio.</span></li>
    <li><span class="authors">Daniel Rosenberg Munoz</span>	<span class="title"> SoundHolo: Sonically Augmenting Everyday Objects and the Space Around The.</span></li>
    <li><span class="authors">Sosuke Ichihashi, Noura Howell, HyunJoo Oh</span>	<span class="title"> Swell by Light: An Approachable Technique for Freeform Raised Texture.</span></li>
    <li><span class="authors">Eldy S. Lazaro Vasquez, Sepideh Mohammadi, Latifa Al Naimi, Shira David, Mirela Alistar</span>	<span class="title"> Bio-e-Nails: a Sustainable Design Approach to Biobased Nail Interface.</span></li>
    <li><span class="authors">Sara Mlakar, Mira A. Haberfellner, Kathrin Probst</span>	<span class="title"> Signifiers in Textile-Specific Interaction Desig.</span></li>
    <li><span class="authors">Sosuke Ichihashi, Kosha Bheda, Noura Howell</span>	<span class="title"> Towards Designing for Everyday Thermal Experience.</span></li>
    <li><span class="authors">Valentina Nisi, Mathilde Gouin, Marta Galvão Ferreira, Nuno Jardim Nunes</span>	<span class="title"> From visual sketching to knotted figurations: Fabulating in the contact zon.</span></li>
    <li><span class="authors">Feng Feng, Elisa D. Mekler</span>	<span class="title"> Thermal Cards: From Classical Chinese Poetry to Designerly Lenses on Thermal-Affective Technolog.</span></li>
    <li><span class="authors">Alexandra Teixeira Riggs, Michael Nitsche, Noura Howell</span>	<span class="title"> Mold Sounds: Queering Ecologies in Polyphonic Material Exploration.</span></li>
    <li><span class="authors">Caroline Claisse, David Chatting, Sara Wolf, Ben Morris, Abigail C Durrant</span>	<span class="title"> Resound: A Moment of Reflection in a Techno-Spiritual RtD Inquiry.</span></li>
    <li><span class="authors">Jing Ya Huang, Hannah Eikens, Miguel Bruns, Amy Winters</span>	<span class="title"> Aqua-Morph: A Design Method for Fabricating Shape-Changing Hydrogel Structures.</span></li>
    <li><span class="authors">Joseph Lindley, Roger Whitham</span>	<span class="title"> From Prompt Engineering to Prompt Craf.</span></li>

    </ul>
  </main>
</template>

<script setup>
</script>

<style scoped>
.title {
  font-weight: bold;
  margin-left: 2px;
}
</style>
