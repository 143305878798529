<script setup>

</script>

<template>
<main>
  <h1>Route to Agora du Haut-carré</h1>
  <p>The <b>main conference</b> sessions will take place at the Agora du Haut-carré: Tram B — Talence Centre-Forum stop</p>
  <img src="@/assets/routeToAgora.webp"/>
</main>
</template>

<style scoped>
img{
  width: 100%;
}
</style>
