<script setup>

</script>

<template>
<main>
  <h1>Accommodation</h1>

  Bordeaux offers a wide range of hotel accommodation, from charming vineyard locations to business hotels near the congress centers, ranging from 18 to 250 rooms.
  More informations can be found <a href="https://www.bordeaux-tourism.co.uk/accommodation/hotels-serviced-apartments.html">here</a>
</main>
</template>

<style scoped>

</style>
